import React, { useEffect, useMemo, useState } from 'react';
import _ from "lodash";

import { addHotSpot, removeHotSpot } from 'react-pannellum';

import { navigate } from "gatsby";

import { getEntityUrl } from "../../util";

import ThreeSixtyWrapper from './wrapper';

const ThreeSixtyViewer = (props) => {
    const { entity, component, files, fullscreenMode, pageContext } = props;
    const { defaultLocale } = pageContext;


    const image = _.find(files.nodes, { id: component.threeSixtyImage.localFile___NODE });

    const threeSixtyImage = useMemo(() => {
        return {
            imageSource: image.publicURL,
            equirectangularOptions: {},
        }
    }, [component]);

    const [viewer, setViewer] = useState();
    useEffect(() => {
        const removeHotspots = () => {
            entity.strapiChildren.forEach(c => {
                removeHotSpot(c.identifier, entity.identifier);
            });
        }
        entity.strapiChildren.forEach(c => {
            if (viewer) {
                const { Roles, identifier, locale } = c;

                const role = _.find(Roles, { strapi_component: 'role.three-sixty-child' });
                const url = getEntityUrl({ entity: c, defaultLocale });
                if (role) {
                    addHotSpot(
                        {
                            pitch: parseFloat(role.pitch),
                            yaw: parseFloat(role.yaw),
                            type: role.type || "info",
                            text: role.hotspotLabel,
                            sceneId: identifier,
                            id: identifier,
                            URL: url,
                            clickHandlerFunc: (e) => {
                                if (e.target.nodeName === 'SPAN') {
                                    removeHotspots();
                                    navigate(url);
                                }
                                e.preventDefault();
                                return false;

                            },

                        },
                        entity.identifier
                    );
                }


            }
        });

        return () => removeHotspots();
    }, [entity, viewer]);

    const threeSixtyProps = { viewer, setViewer, threeSixtyImage };

    return <ThreeSixtyWrapper {...props} {...threeSixtyProps} />

}

export default ThreeSixtyViewer;