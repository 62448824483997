
import React, { Fragment, useState } from "react";
import _ from "lodash";
import Collapsible from 'react-collapsible';

import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge"
import { Link } from "gatsby"
import { getEntityUrl } from "../../util";

import Menu from "../imagelist/menu";
import EntityKeypad from "../../components/Keypad";

import { makeStyles } from "@material-ui/core/styles";

import Wrapper from "../../components/Wrapper";
import ComponentsWrapper from "../../components/ComponentsWrapper";


import List from "./list";

import "./richtext.css"
import "./collapsible.css"

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '0',
        textDecoration: 'none',
        fontFamily: 'opensans, Verdana, Helvetica, Arial, sans-serif;',
    },

    headline: {
        padding: '14px 8px',
        fontSize: '20px',
        lineHeight: '15px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: '#000',
       /* Standart backgroundColor: '#fff', */
       /* Custom 1*/ backgroundColor: '#f5f5f5',
    },
    padding: {
        padding: theme.spacing(1)
    },
    bildbutton: {
        display: 'block',
        position: 'absolute',
        fontFamily: 'opensans, Verdana, Helvetica, Arial, sans-serif',
        fontStretch: 'normal',
        font:'15px opensans, Verdana, Helvetica, Arial, sans-serif',
        /* font-family: 'openlight', */
        bottom: '12px',
        left: '10px',
        whiteSpace: 'nowrap',
        padding: '8px 15px',
        background: 'hsla(0, 0%, 0%, 0.27)',
        fontSize: '16px',
        color: '#ffffff!important',
        border: '1px solid #FFF',
        letterSpacing: '0.06em',
        textDecoration: 'none!important',
        textTransform: 'uppercase',
        fontWeight: 600,
        /* Custom 1 */
        //  boxShadow: '0 0 0 7px rgba(183, 183, 183, 0.5), 0 0 0 21px rgba(201, 201, 201, 0.3), 0 0 0 63px rgba(211, 211, 211, 0.2), 0 0 0 186px rgba(201, 201, 201, 0.1), 0 0 0 558px rgba(190, 190, 190, 0.1)',
        // borderRadius: '50%',
        //padding: '7px 55px 7px 55px',
        /* End Custom 1 */
    },
}));

const EntityPage = (props) => {
    const { entity, config, selectedModule, pageContext } = props;

    const classes = useStyles();

    const { defaultLocale } = pageContext;

    const image = entity.largeImage ? getImage(entity.largeImage.localFile) : null;
    const headline = (entity.shortDesc || '').replace(/(?:\r\n|\r|\n)/g, '<br />');

    const [keypadShown, setKeypadShown] = useState(false);//TODO - survive reload, check cookbook ch3 - redux-reducer, gatsby redux example, gatsby-redux-plugin
    const keypadToggle = () => {
        setKeypadShown(!keypadShown);
    };

    return selectedModule !== 'renoir' ?
        <></>
        :
        <div className="renoir">
            <Wrapper {...props} menu={Menu} title={entity.title} keypadSwitch={config.showKeypad || false} keypadShown={keypadShown} onKeypad={keypadToggle}>
                {keypadShown ?
                    <EntityKeypad {...{ entity }} {...props} /> :

                    <div className={classes.root}>
                        {image !== null &&
                            <div style={{width:'100%', height: '296px', padding: '5px 5px 0 5px'}}>
                                <BgImage className={classes.img} image={image} alt={headline} layout="fullWidth" style={{width: '100%', height: '100%'}}>
                                { entity.headerLink !== undefined && 
                                    <Link className={` ${classes.bildbutton}`}
                                    to={getEntityUrl({ entity: entity.headerLink, defaultLocale })}>{entity.headerLink.title}</Link>
                                }
                                </BgImage>
                            </div>
                        }
                        { headline?.length > 0 && <div className={` ${classes.headline}`}>{headline}</div> }

                        <ComponentsWrapper {...props} showHeadline />



                        <List {...props} />
                    </div>
                }
            </Wrapper>
        </div>
};

export default EntityPage;
