import React, { useState, useEffect, useCallback } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { alpha } from '@material-ui/core/styles/colorManipulator';

import FullScreenIcon from "@material-ui/icons/Fullscreen";
import CloseFullScreenIcon from "@material-ui/icons/FullscreenExit";

import { makeStyles } from "@material-ui/core/styles";

const ENABLE_NATIVE_FULLSCREEN_API = false;

const FullScreenWrapper = React.forwardRef(({ component, fullscreenMode, setFullscreenMode, children, hideButton, buttonStyle }, ref) => {
    const [isFullscreen, setIsFullscreen] = useState(fullscreenMode || false);

    const fullscreenToggle = useCallback(() => {
        setIsFullscreen(!isFullscreen);
    }, [setIsFullscreen, isFullscreen]);

    useEffect(() => {
        setFullscreenMode(isFullscreen);
    }, [isFullscreen]);

    const fullscreenHandle = useFullScreenHandle();//TODO - remove if fullscreen-api not used
    useEffect(() => {
        if (ENABLE_NATIVE_FULLSCREEN_API && document.fullscreenEnabled) {
            if (isFullscreen) fullscreenHandle.enter();
            else fullscreenHandle.exit();
        }
    }, [isFullscreen]);

    const [FullscreenButton, setFullscreenButton] = useState(FullScreenIcon);
    useEffect(() => {
        if (component.fullscreen) {
            setFullscreenButton('div');
        } else {
            if (isFullscreen) {
                setFullscreenButton(CloseFullScreenIcon);
                document.body.style.overflow = "hidden";
                window.scrollTo(0, 0);
            } else {
                setFullscreenButton(FullScreenIcon);
                document.body.style.overflow = "auto";
            }
        }
    }, [isFullscreen]);

    const FullscreenElement = ENABLE_NATIVE_FULLSCREEN_API ? FullScreen : 'div';//isFullscreen ? 'div' : 'div';//TODO - fullscreen api?

    const classes = useStyles();

    const fullscreenElementProps = ENABLE_NATIVE_FULLSCREEN_API ?
        {
            handle: fullscreenHandle,
            fullscreen: isFullscreen,
            open: true,
            className: isFullscreen ? `${classes.fullscreen}` : ''

        }
        :
        {
            className: isFullscreen ? `${classes.fullscreen}` : ''
        };

    return <FullscreenElement {...fullscreenElementProps} ref={ref}>
        <div className={isFullscreen ? classes.centeredContent : ''}>
            {children}
            <div style={{ position: 'relative' }}>
                {hideButton !== true &&
                    <FullscreenButton onClick={fullscreenToggle} style={buttonStyle}
                        className={`${classes.fullScreenButton} ${isFullscreen ? classes.fullScreenButtonFull : ''}`} />
                }
            </div>
        </div>
    </FullscreenElement>
});

export default FullScreenWrapper;

const useStyles = makeStyles((theme) => ({
    fullscreen: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 65530,
        backgroundColor: '#000',
        maxHeight: '100vh',
    },
    centeredContent: {
        marginTop: '50vh',
        transform: 'translateY(-50%)',
        position: 'relative',
        overflowY: 'hidden',
    },
    fullScreenButton: {
        position: 'absolute',
        right: 0,
        bottom: '7px',
        zIndex: 65520,
        color: '#ccc',
    },
    fullScreenButtonFull: {
        bottom: 0,
        color: '#fff',
        backgroundColor: alpha('#000', .3),
    },
}));