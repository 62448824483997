import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Collapsible from 'react-collapsible';
import { getImage } from "gatsby-plugin-image";

import Videoplayer from "./Videoplayer/";
import Imageslider from "./Imageslider";
import Imageplayer from "./Imageplayer";
import Text from "./Text";
import ThreeSixtyViewer from "./ThreeSixtyViewer";
import Map from "./Map.js";

const useStyles = makeStyles((theme) => ({
    padding: {
        padding: theme.spacing(1)
    },

    headline: {
        padding: '14px 8px',
        fontSize: '20px',
        lineHeight: '15px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: '#000',
       /* Standart backgroundColor: '#fff', */
       /* Custom 1*/ backgroundColor: '#f5f5f5',
    },
}));

const ComponentsWrapper = (props) => {
    const { entity, showHeadline } = props;

    const image = entity.largeImage ? getImage(entity.largeImage.localFile) : null;
    const headline = (entity.shortDesc || ' ').replace(/(?:\r\n|\r|\n)/g, '<br />');

    const classes = useStyles();
    return <>{
        entity.Components.map((component, n) => {
            const Wrap = component.collapsible ?
                ({ children, component }) =>
                    <Collapsible className={classes.collapsible} trigger={component.label || 'Click here'} open={!component.collapsed}>
                        {children}
                    </Collapsible>

                :

                ({ children }) => <>{children}</>

            return <Wrap key={`${component.strapi_component}${n}`} component={component}>{(() => {
                switch (component.strapi_component) {
                    case 'component.videoplayer':
                        return <Videoplayer component={component} entity={entity} key={`component-${n}`} {...props} />

                    case 'component.imageslider':
                        return <>
                            <Imageslider component={component} entity={entity} {...props} />
                            {(showHeadline === true && n === 0 && image === null) ? <div className={classes.headline}>{headline}</div> : <></>
                                /*TODO - ugly */
                            }
                        </>

                    case 'component.imageplayer':
                        return <Imageplayer component={component} entity={entity} key={`component-${n}`} {...props} />

                    case 'component.text':
                        return <div className={classes.padding} key={`component-${n}`} ><Text component={component} entity={entity} {...props} /></div>

                    case 'component.three-sixty-viewer':
                        return <ThreeSixtyViewer component={component} entity={entity} key={`component-${n}`} {...props} />
                    case 'component.map':
                        return <Map component={component} entity={entity} key={`component-${n}`} {...props} />
                    default:
                        return <></>;
                }
            })()}
            </Wrap>
        })
    }</>
}

export default ComponentsWrapper
