
import React, { Fragment, useState } from "react";
import _ from "lodash";

import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby"
import { getEntityUrl } from "../../util";

import Menu from "../imagelist/menu";
import EntityKeypad from "../../components/Keypad";

import { makeStyles } from "@material-ui/core/styles";

import Wrapper from "../../components/Wrapper";


const useStyles = makeStyles((theme) => ({
    listItem: {
        textDecoration: 'none',
        display: 'block',
        cursor: 'pointer',
        background: '#efefef',
        padding: '0',
         /* STANDART margin: '0px 0px 6px 0px',*/ 
        listStyle: 'none',
        display: 'flex',
        justifyContent: 'flex-start',
        minHeight: '48px',
        /* CUSTOM 1*/
        margin: '5px 6px 5px 6px',
        overflow: 'hidden',  
        alignItems: 'center',
        borderRadius: '10px',
        boxShadow: '0 5px 7px -1px rgba(51, 51, 60, 0.4)',
        rowGap: '8px', 
  
    },
    
    listLink: {
        textDecoration: 'none',
        color: '#000',
        
    }, 

    /* STANDART
    listImg: {
        maxWidth: '110px',
        display: 'flex',

    },*/
    
    /* CUSTOM 1 */

    listImg: {
        width: '110px',
        minWidth: '110px',
        height: '80px',
        borderRadius: '50%',
        boxShadow: '0 0 0 7px #b7b7b7, 0 0 0 21px #C9C9C9, 0 0 0 63px #D3D3D3, 0 0 0 186px #E1E1E1, 0 0 0 558px #e7e7e7',
        display: 'flex',
     },

    

      /* End CUSTOM 1*/

    titleDiv: {
        minWidth: '0',
        overflow: 'hidden',


        padding: '10px 28px 10px 8px'

    },
    titleFlex: {

        display: 'flex',
        flexGrow: '1',

        alignItems: 'center',
        position: 'relative',
        minHeight: '80px',
    },
    title1: {
        fontSize: '15px',
        textTransform: 'uppercase',
        marginBottom: '0',
        paddingRight: '25px',
        lineHeight: '1.15',
        fontWeight: 'bold',
    },
    title2: {
        fontSize: '14px',
        lineHeight: '1.2',
        marginTop: '3px',
        paddingRight: '20px',
    },
    title3: {
        fontSize: '14px',
        marginBottom: '0px',
        marginTop: '2px',
        fontWeight: 'normal',
    },
    numCode: {
        position: 'absolute',
        right: '10px',
        bottom: '7px',
        fontSize: '13px',
        fontWeight: 'bold'
    },
    playButton: {
        position: 'absolute',
        right: '6px',
        top: '50%',
        transform: 'translateY(-50%)',
    }
}));

const List = ({ entity, pageContext }) => {
    const { strapiChildren: entities } = entity;

    const classes = useStyles();

    const { defaultLocale } = pageContext;

    return <ul>
        {entities.map(entity => {
                const listRole = _.find(entity.Roles, { strapi_component: 'role.list-child' });

                const title1 = listRole?.line1 || entity.title || '';
                const title2 = listRole?.line2 || false;
                const title3 = listRole?.line3 || false;

                const numCode = parseInt(listRole?.numCode || 0);

                const image = entity.smallImage ? getImage(entity.smallImage.localFile) : null;
                return (
                    <Fragment key={entity.identifier}>
                        <Link to={getEntityUrl({ entity, defaultLocale })} className={classes.listLink}>
                            <li key={entity.identifier} className={classes.listItem}>

                                {image !== null ?
                                    <GatsbyImage className={classes.listImg} image={image} alt={title1} layout="fullWidth" /> : <></>}
                                <div className={classes.titleFlex}>
                                    <div className={classes.titleDiv}>
                                        <div className={classes.title1}>{title1}</div>
                                        <div className={classes.title2}>{title2}</div>
                                        <div className={classes.title3}>{title3}</div>
                                        <div className={classes.numCode}>{numCode}</div>
                                        <div className={classes.playButton}>
                                            <img src={`/images/bigarrow.png`} alt="play" />
                                        </div>
                                    </div>
                                </div>

                            </li>
                        </Link>

                    </Fragment>
                );

            })}
    </ul>

};

export default List;
