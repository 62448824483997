import React, { useMemo, useCallback, useState, useEffect } from "react";
import _ from "lodash";
import Player from '../Player';
import FullscreenWrapper from '../FullscreenWrapper';

import { getImage, GatsbyImage } from "gatsby-plugin-image";

import { makeStyles } from "@material-ui/core/styles";
import { Box, } from "@material-ui/core";
import Carousel from "react-material-ui-carousel";

import { navigate } from "gatsby-link";

import { getEntityUrl } from "../../util";

const Imageplayer = (props) => {
    const { component, entity, files, pageContext, fullscreenMode } = props;

    const [images, timestamps] = useMemo(() => {
        const images = {};
        _.each(component.image,
            ({ image, timestamp }) => {
                const key = parseInt(timestamp || 0);

                images[key] = {
                    localFile: _.find(files.nodes, { id: image?.localFile___NODE }),
                    timestamp: key
                }
            });

        const sortedKeys = Object.keys(images).sort((a, b) => a - b);

        return [images, sortedKeys];
    }, [files, component]);

    const audioUrl = useMemo(() => {
        return _.find(files.nodes, { id: component.audio?.localFile___NODE })?.publicURL;
    }, [files, component]);


    const [currentTimestamp, setCurTimeIndex] = useState(0);
    const onProgress = useCallback(({ playedSeconds }) => {
        const playedMs = parseInt(playedSeconds * 1000);

        const selTime = timestamps.reduce((prev, curr) => {
            return playedMs > curr ? curr : prev
        });

        setCurTimeIndex(timestamps.findIndex(e => e === selTime));
    }, [setCurTimeIndex, timestamps]);

    const carouselProps = {
        autoPlay: false,
        navButtonsProps: { style: { display: 'none' } },
        indicators: false,
        index: currentTimestamp,
        indicatorContainerProps: {
            style: {
                marginTop: fullscreenMode ? '-25px' : '4px',
            }
        },
    };

    const { defaultLocale } = pageContext;
    const onEnded = () => {
        if (component.continueTo) navigate(getEntityUrl({ entity: component.continueTo, defaultLocale }));
    }

    const classes = useStyles();

    const [playerStyle, setPlayerStyle] = useState('');
    useEffect(() => {
        setPlayerStyle(fullscreenMode ? classes.playerFullscreen : '')
    }, [fullscreenMode]);

    return <FullscreenWrapper {...props}> {
        timestamps.length > 0 && (
            timestamps.length === 1 ?
                <GatsbyImage image={getImage(images[timestamps[0]].localFile)} alt="" />
                :
                <Carousel {...carouselProps} className="imageslider" >
                    {timestamps.map(k =>
                        <Box align="center" key={k}>
                            <GatsbyImage image={getImage(images[k].localFile)} style={{ width: '100%' }} imgStyle={{ position: 'static', maxHeight: '100vh' }} alt="" loading={k === 0 ? 'eager' : 'lazy'} objectFit="contain" position="static" />
                            {images[k].caption !== '' &&
                                <div className={fullscreenMode ? classes.fullScreenCaption : ''}>{images[k].caption}</div>}
                        </Box>
                    )}
                </Carousel>
        )}
        {audioUrl !== undefined &&
            <div className={playerStyle}>
                <Player style={{ paddingTop: 12 }} width='100%' height='42px'
                    url={audioUrl}
                    controls={true}
                    loop={component.loop || false}
                    playing={component.autoplay || false}
                    playsinline={component.fullscreen || false}
                    onProgress={onProgress}
                    onEnded={onEnded}
                />
            </div>
        }
    </FullscreenWrapper>
}

export default Imageplayer;

const useStyles = makeStyles((theme) => ({//TODO - dup
    fullScreenCaption: {
        position: 'absolute',
        bottom: '16px',
        zIndex: 65535,
        color: '#000',
        width: '100%',
        overflowWrap: 'break-word',
    },
    playerFullscreen: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
    },
}));