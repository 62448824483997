import React from "react";
import _ from "lodash";

import { makeStyles } from "@material-ui/core/styles";

import Header from "./header";
import Wrapper from "../../components/Wrapper";
import ComponentsWrapper from "../../components/ComponentsWrapper";


import EntityList from "../../components/List";

const useStyles = makeStyles((theme) => ({
    root: {
        //padding: theme.spacing(1)
    },
}));

const EntityPage = (props) => {
    const { entity, config, selectedModule } = props;
    
    const classes = useStyles();

    return selectedModule !== 'material' ? 
        <></>
        :
        <Wrapper {...props} title={entity.title /*TODO - discuss/define*/}>
            <div className={classes.root}>
                <Header entity={entity} />
                <ComponentsWrapper {...props} />

                {   
                    entity.strapiChildren.length > 0 &&
                        <EntityList {...props} />
                }
            </div>
        </Wrapper>
};

export default EntityPage;
