import React, { useMemo, useState, useEffect, useCallback } from "react";

import _ from "lodash";
import Player from '../Player';
import FullscreenWrapper from '../FullscreenWrapper';

import { getImage, GatsbyImage } from "gatsby-plugin-image";

import { Box, } from "@material-ui/core";
import Carousel from "react-material-ui-carousel";

import { makeStyles } from "@material-ui/core/styles";

const Imageslider = (props) => {
    const { component, files, fullscreenMode } = props;

    const images = useMemo(() => {
        const images = [];
        _.each(component.images, i => images.push(_.find(files.nodes, { id: i.localFile___NODE })));
        return images;
    }, [files, component]);

    const audioUrl = useMemo(() => {
        return _.find(files.nodes, { id: component.audio?.localFile___NODE })?.publicURL;
    }, [files, component]);

    component.duration = parseInt(component.duration);

    const carouselProps = { //TODO - discuss
        stopAutoPlayOnHover: true,
        autoPlay: component.duration !== 0,
        interval: (component.duration || 3) * 1000,
        //animation: 'slide', //default is fade - discuss
        indicatorContainerProps: {
            style: {
                marginTop: fullscreenMode ? '-25px' : '4px',
            }
        },
    };

    const classes = useStyles();

    const [playerStyle, setPlayerStyle] = useState('');
    useEffect(() => {
        setPlayerStyle(fullscreenMode ? classes.playerFullscreen : '')
    }, [fullscreenMode]);

    return <FullscreenWrapper {...props} >
        {
            images.length > 0 && (
                images.length === 1 ?
                    <div className="imageslider">
                        <GatsbyImage image={getImage(images[0])} alt="" objectFit="contain" loading="eager" imgStyle={{ position: 'static', maxHeight: '100vh' }} />
                    </div>
                    :
                    <Carousel {...carouselProps} className="imageslider" >
                        {images.map((i, n) =>
                            <Box key={i.id} align="center">
                                <GatsbyImage image={getImage(i)} style={{ width: '100%' }} imgStyle={{ position: 'static', maxHeight: '100vh' }} alt="" loading={n < 10 ? 'eager' : 'lazy'} objectFit="contain" position="static" />
                                {component.images[n].caption !== '' &&
                                    <div className={fullscreenMode ? classes.fullScreenCaption : ''}>{component.images[n].caption}</div>}

                            </Box>
                        )}
                    </Carousel>
            )}
        {audioUrl !== undefined &&
            <div className={playerStyle}>
                <Player style={{ paddingTop: '12px' }} width='100%' height='28px'
                    url={audioUrl}
                    controls={true}
                    loop={true /*TODO - discuss*/}
                    playing={component.autoplayAudio || false}
                    playsinline={component.fullscreen || false}
                />
            </div>
        }

    </FullscreenWrapper>
}

export default Imageslider;

const useStyles = makeStyles((theme) => ({
    fullScreenCaption: {
        position: 'absolute',
        bottom: '16px',
        zIndex: 65535,
        color: '#000',
        width: '100%',
        overflowWrap: 'break-word',
    },
    playerFullscreen: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
    },
}));